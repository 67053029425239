import shave from 'shave';
import { $win, getCurrentDevice } from './utils';

/**
 * Shave
 * How to use?
 *
 * Default version:
 * <div data-shave>Lorem ipsum</div>
 *
 * Number of line
 * <div data-shave="3">Lorem ipsum</div>
 *
 * Specify devices
 * <div data-shave data-shave-tablet="5" data-shave-mobile="3">Lorem ipsum</div>
 */

const getMaxHeight = ($el, lines) => {
	return lines * parseFloat($el.css('line-height')) || 72;
}

export const initShave = (selector) => {
	$(selector).each((index, el) => {
		const $el = $(el);
		const defaultLines = $el.data('shave') || 3;
		const devicesLines = {
			desktop: defaultLines,
			tablet: $el.data('shave-tablet') || defaultLines,
			mobile: $el.data('shave-mobile') || defaultLines
		}

		const startShave = () => {
			const maxHeight = getMaxHeight($el, devicesLines[getCurrentDevice()]);

			shave(el, maxHeight);
		}

		startShave();

		$win.on('load resize', function() {
			startShave()
		});
	});
}

export default function shaveUI() {
	initShave('[data-shave]');
}
