import TweenMax from "gsap";
import { $win } from './utils';

export default function parallaxUI() {
	initParallax();
}

const initParallax = () => {
	const $parallaxEl = $('.js-parallax');
	
	$parallaxEl.each(function() {
        const $el = $(this);
        
		$win.on('scroll', function(event) {
			const scrollTop = $win.scrollTop();
			
			const offset = $el.offset().top;
			
			const y = ( scrollTop - offset ) / 3;
			
			TweenMax.set($el[0], { scale: 1 });

			TweenMax.to($el[0], 0.1, {y: y});
		});
	})
}
