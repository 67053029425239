import fullsizeImagesUI from './fullsize';
import slidersUI from './sliders';
import mapsUI from './maps-ui';
import AOS from 'aos';
import shaveUI from './shave-ui';
import tabsUI from './tabs';
import parallaxUI from './parallax';
import { $win, $header } from './utils';

;(function(window, document, $) {
	$('a[href^="tel"]').each(function() {
		const linkHref = $(this).attr('href');

		$(this).attr('href', linkHref.replace(/ /igm, ''));
	});

	$('.js-nav-btn').on('click', function(event) {
		event.preventDefault();
		
		$(this).toggleClass('active');
		$('.header .header__aside').slideToggle();
	});

	if ($('.header:not(.header--transparent)').length) {
		$('.wrapper').addClass('gutter')
	}

	$win.on('scroll', function() {
		$win.scrollTop() > 0 ? $header.addClass('active') : $header.removeClass('active')
	});

	fullsizeImagesUI();

	slidersUI();

	mapsUI();

	shaveUI();

	tabsUI();

	const $dropdown = $('.dropdown');
	const $dropdownLink = $('.nav .has-dropdown > a');
	const $navBtn = $('.nav > ul > li > a');
	const $navBtnParent = $('.nav > ul > li');

	$dropdownLink.on('click', function(event){

		var $link = $(this);

		if ( $win.width() <= 1024 ){
			if ( !$link.hasClass('clicked') ) {
				event.preventDefault();

				$link.addClass('clicked');

				$link
					.siblings('.dropdown')
						.slideToggle()
					.parent()
					.siblings()
						.find('.dropdown')
							.slideUp()
						.siblings()
							.removeClass('clicked')
				}
			}
	});

	$win.on('load resize', function() {
		if ($win.width() > 1024) {
			$navBtnParent.addClass('hover');
			$('.nav').removeAttr('style');
			$dropdown.removeAttr('style');
			$dropdownLink.removeClass('clicked');
		} else {
			$navBtnParent.removeClass('hover');
		}
	});

	const initGallery = () => {
		$('.js-grid').each(function() {
			const $grid = $(this);
			let ist;

			ist = $grid.isotope({
				layoutMode: 'packery',
				itemSelector:  '.js-grid-element',
			});
		});
	}

	initGallery();

	AOS.init({
		duration: 500,
		easing: 'ease-in-cubic',
		// offset: 200,
		once: true
	});

	$('.js-accordion').on('click', function(event) {
		event.preventDefault();

		console.log($(this).next('ul'));
		
		$(this).next('ul').slideToggle()
	});
	
	parallaxUI();
})(window, document, window.jQuery);
