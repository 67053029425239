export default function tabsUI() {
	initTabs();
}

const initTabs = () => {
	$('.js-toggle-class').on('click', function(event) {
		event.preventDefault();
		
		$(this).parents('.member').toggleClass('active').siblings().removeClass('active');
	});
}
